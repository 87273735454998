import { Moment } from 'moment';
import { PropertyEntityType, EventLevelType, SubSystemType } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { download, post } from '@common-src/service/request';
import { assignDefaultExtend } from '@common-src/service/report';
import ParkingFacilityService from '../service/parking-facility';
import { changeTreeDataFieldName, getTreeIdByDepth, timeTransformData } from '../data-factory';
import { dateFormat } from '@common-src/filter';
import CommonService from '@common-src/service/common';
import { eventLevelList } from '@common-src/filter/event-level';
import { getEndTimeByIntervalType, ReportExtraQueryModel } from './parking-report-model';
import { generateUUID } from '@common-src/utils/base_util';
import { getDateFormatByPeriodTypeEnum } from '@common-src/filter/period-type';

const ALARM_STATUS_OPTIONS = [
    { value: 'YCL', name: '已处理', dataIndex: 'solvedCount' },
    { value: 'WCL', name: '未处理', dataIndex: 'unsolvedCount' }
];

const ALARM_TOTAL_OPTIONS = [
    { value: 'totalCount', name: '告警总数' }
];

enum AlarmTypeEnum {
    ALARM_TOTAL = 'AlarmTotal', // 告警总数
    ALARM_STATUS = 'AlarmStatus', // 告警状态
    ALARM_GRADE = 'AlarmGrade', // 告警级别
    ALARM_LEVEL = 'AlarmLevel' // 告警类型
}
export class FireReportQueryModel extends ReportExtraQueryModel {
    subSystemType: SubSystemType;
    constructor(type: SubSystemType) {
        super();
        this.subSystemType = type;
    }

    @QueryControl({
        label: '属性类型',
        type: QueryControlType.SELECT,
        options: [
            { value: AlarmTypeEnum.ALARM_TOTAL, name: '告警总数' },
            { value: AlarmTypeEnum.ALARM_STATUS, name: '告警状态' },
            { value: AlarmTypeEnum.ALARM_GRADE, name: '告警级别' },
            { value: AlarmTypeEnum.ALARM_LEVEL, name: '告警类型' }
        ],
        span: 6,
        required: true
    })
    type: string = AlarmTypeEnum.ALARM_STATUS;

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: ALARM_TOTAL_OPTIONS,
        mode: 'multiple',
        span: 6,
        required: true,
        defaultValue: 'ALL',
        invisibleFunction: model => model.type !== AlarmTypeEnum.ALARM_TOTAL
    })
    alarmTotalAttr: Array<string> = undefined;

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: ALARM_STATUS_OPTIONS,
        mode: 'multiple',
        span: 6,
        required: true,
        defaultValue: 'ALL',
        invisibleFunction: model => model.type !== AlarmTypeEnum.ALARM_STATUS
    })
    alarmStatusAttr: Array<string> = undefined;

    @QueryControl({
        label: '告警级别',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.EVENT_GRADE,
        hasAllOption: false,
        mode: 'multiple',
        defaultValue: 'ALL',
        invisibleFunction: model => model.type !== AlarmTypeEnum.ALARM_GRADE
    })
    alarmGradeAttr: Array<string> = undefined;

    @QueryControl({
        label: '告警类型',
        type: QueryControlType.SELECT,
        options: eventLevelList,
        hasAllOption: false,
        mode: 'multiple',
        defaultValue: 'ALL',
        invisibleFunction: model => model.type !== AlarmTypeEnum.ALARM_LEVEL
    })
    alarmLevelAttr: Array<string> = undefined;

    @QueryControl({
        label: '区域选择',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: 'subSystemType',
        mode: 'multiple',
        selectChildren: true,
        span: 6,
        defaultValue: 'ALL',
        required: true
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 6,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        const params: any = {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: getEndTimeByIntervalType(this.dateRange[1], this.intervalType),
            merge: this.merge,
            intervalType: this.intervalType,
            filterAttrName: this.type
        };
        let paramsExtra = null;
        switch (this.type) {
            case AlarmTypeEnum.ALARM_TOTAL:
                params.filterAttrValues = this.alarmTotalAttr;
                break;
            case AlarmTypeEnum.ALARM_STATUS:
                params.filterAttrValues = this.alarmStatusAttr;
                break;
            case AlarmTypeEnum.ALARM_GRADE:
                params.filterAttrValues = this.alarmGradeAttr;
                break;
            case AlarmTypeEnum.ALARM_LEVEL:
                params.filterAttrValues = this.alarmLevelAttr;
                break;
        }
        return Object.assign(params, paramsExtra);
    }
}

function handleValues(resObj) {
    if (resObj && resObj.values) {
        _.forEach(resObj.values, val => {
            resObj[val.key] = val.value;
        });
    }
}

export async function FireReportService(queryModel: FireReportQueryModel) {
    let url = null;
    switch (queryModel.subSystemType) {
        case SubSystemType.FIRE:
            url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/alarm/hts/advanced`;
            break;
        case SubSystemType.CAMERA:
            url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/alarm/hts/advanced`;
            break;
        case SubSystemType.ENVIROMENT:
            url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/alarm/hts/advanced`;
            break;
        case SubSystemType.BA:
            url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/alarm/hts/advanced`;
            break;
        case SubSystemType.CUSTOMER_FLOW:
            url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/alarm/hts/advanced`;
            break;
        case SubSystemType.ELEVATOR:
            url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/alarm/hts/advanced`;
            break;
        case SubSystemType.ACCESS_CONTROL:
            url = `${BIBIZ_BASE_REQUEST_PATH}/acs/alarm/hts/advanced`;
            break;
        case SubSystemType.COMPUTER_ROOM:
            url = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem/alarm/hts/advanced`;
            break;
        default:
            throw new Error('Not implement.');
    }
    const columns = [{ code: 'ts', name: '时间' }];
    const tableColumns: Array<any> = [{
        title: '时间',
        customRender: (text, record, index) => {
            return dateFormat(record.ts, getDateFormatByPeriodTypeEnum(queryModel.intervalType)) || record.locationName;
        }
    }];
    switch (queryModel.type) {
        case AlarmTypeEnum.ALARM_TOTAL:
            tableColumns.push(..._.map(queryModel.alarmTotalAttr, item => {
                const selectAlarmTotal = _.find(ALARM_TOTAL_OPTIONS, option => option.value === item);
                columns.push({ code: selectAlarmTotal?.value, name: selectAlarmTotal?.name });
                return {
                    title: selectAlarmTotal?.name,
                    dataIndex: selectAlarmTotal?.value
                };
            }));
            break;
        case AlarmTypeEnum.ALARM_STATUS:
            tableColumns.push(..._.map(queryModel.alarmStatusAttr, item => {
                const selectAlarmStatus = _.find(ALARM_STATUS_OPTIONS, typeItem => typeItem.value === item);
                columns.push({ code: selectAlarmStatus?.dataIndex, name: selectAlarmStatus?.name });
                return {
                    title: selectAlarmStatus?.name,
                    dataIndex: selectAlarmStatus?.dataIndex
                };
            }));
            break;
        case AlarmTypeEnum.ALARM_GRADE: {
            const eventGradeList = await CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE);
            tableColumns.push(..._.map(queryModel.alarmGradeAttr, item => {
                const selectAlarmGrade = _.find(eventGradeList, eventGradeItem => eventGradeItem.value === item);
                columns.push({ code: selectAlarmGrade?.code, name: selectAlarmGrade?.name });
                return {
                    title: selectAlarmGrade?.name,
                    dataIndex: selectAlarmGrade?.code
                };
            }));
            break;
        }
        case AlarmTypeEnum.ALARM_LEVEL:
            tableColumns.push(..._.map(queryModel.alarmLevelAttr, (item: EventLevelType) => {
                const selectAlarmLevel = _.find(eventLevelList, typeItem => typeItem.value === item);
                let dataIndex = null;
                switch (item) {
                    case EventLevelType.ALERT:
                        dataIndex = 'warningCount';
                        break;
                    case EventLevelType.ERROR:
                        dataIndex = 'errorCount';
                        break;
                    case EventLevelType.INFO:
                        dataIndex = 'infoCount';
                        break;
                }
                columns.push({ code: dataIndex, name: selectAlarmLevel?.name });
                return {
                    title: selectAlarmLevel?.name,
                    dataIndex: dataIndex
                };
            }));
            break;
    }

    const res = await post(url, queryModel?.toService());
    res.locationName = '合计';
    res.locationId = 'id_all';
    handleValues(res);
    if (res.children) {
        _.forEach(res.children, child => {
            handleValues(child);
        });
    }
    let resData = null;
    if (res.data && res.data.length > 0) {
        resData = [res];
    } else {
        resData = res.children;
    }
    const chartOptions = {
        data: timeTransformData(resData, columns, getDateFormatByPeriodTypeEnum(queryModel.intervalType), 'locationName'),
        settings: {
            type: 'line'
        },
        extend: {
            series: {
                type: 'line',
                showSymbol: queryModel.showNumber,
                label: {
                    show: queryModel.showNumber,
                    position: 'top'
                }
            }
        },
        tableRowKey: (record) => {
            return record.locationId || generateUUID();
        },
        tableExpandedRowKeys: getTreeIdByDepth([res], 'locationId', 2),
        tableRows: changeTreeDataFieldName([res], 'data', 'children'),
        tableColumns
    };
    return assignDefaultExtend(chartOptions);
}

export async function FireReportExportService(queryModel: FireReportQueryModel) {
    let url = null;
    switch (queryModel.subSystemType) {
        case SubSystemType.FIRE:
            url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/alarm/export/advanced`;
            break;
        case SubSystemType.CAMERA:
            url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/alarm/export/advanced`;
            break;
        case SubSystemType.ENVIROMENT:
            url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/alarm/export/advanced`;
            break;
        case SubSystemType.BA:
            url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/alarm/export/advanced`;
            break;
        case SubSystemType.CUSTOMER_FLOW:
            url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/alarm/export/advanced`;
            break;
        case SubSystemType.ELEVATOR:
            url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/alarm/export/advanced`;
            break;
        default:
            throw new Error('Not implement.');
    }
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
