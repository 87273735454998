import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@common-src/model/enum';
import CameraIndexService from '../service/camera-index';
import { download } from '@common-src/service/request';

export class CameraFacilityStatusQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.CAMERA,
        mode: 'default',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        hasAllOption: false,
        required: false
    })
    locationId: string = undefined;

    toService() {
        return {
            locationId: this.locationId
        };
    }
}

export async function CameraFacilityStatusService(model: CameraFacilityStatusQueryModel) {
    const res = await CameraIndexService.getFacilityStatusCount(model?.toService());
    const chartOptions = {
        title: '设备状态统计',
        data: res.data,
        settings: Object.assign({ type: 'histogram' }, res.settings),
        extend: res.extend,
        tableRowKey: 'locationId',
        tableColumns: [
            {
                title: '区域',
                dataIndex: 'locationName'
            },
            {
                title: '在线',
                dataIndex: 'onlineCount'
            },
            {
                title: '离线',
                dataIndex: 'offlineCount'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function CameraFacilityStatusExportService(queryModel: CameraFacilityStatusQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/facility/rts/export/statusCountByLocation`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
