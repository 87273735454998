import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@common-src/model/enum';
import CameraIndexService from '../service/camera-index';
import { download } from '@common-src/service/request';

export class CameraAlarmStatusQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.CAMERA,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        required: false
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        return {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.getEndTime(this.dateRange[1])
        };
    }
}

export async function CameraAlarmStatusService(model: CameraAlarmStatusQueryModel) {
    const res = await CameraIndexService.getAlarmStatusCountByType(model?.toService());
    const chartOptions = {
        title: '告警状态统计',
        data: res.data,
        settings: Object.assign({ type: 'histogram' }, res.settings),
        extend: res.extend,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '事件类型',
                dataIndex: 'name'
            },
            {
                title: '未处理',
                dataIndex: 'unsolvedCount'
            },
            {
                title: '已处理',
                dataIndex: 'solvedCount'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function CameraAlarmStatusExportService(queryModel: CameraAlarmStatusQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/alarm/hts/export/statusCountByType`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
