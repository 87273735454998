import { get, download } from '@common-src/service/request';
import { assignDefaultExtend } from '@common-src/service/report';
import { percentFormat } from '@common-src/filter';
import { getTreeIdByDepth } from '../data-factory';

export async function ParkingRateService() {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingSpace/rts/usage`;
    const res = await get(url);
    const allData = {
        title: `${res.locationName} | 实时统计`,
        type: 'jtl-custom-gauge',
        data: res.rate,
        children: res.children,
        percentDesc: '停车率',
        chartDesc: [
            {
                name: '车位总数',
                value: res.totalCount
            },
            {
                name: '剩余车位',
                value: res.freeCount
            }
        ]
    };
    const chartOptions = {
        data: [allData, ..._.map(res.children, item => {
            return {
                title: `${item.locationName} | 实时统计`,
                type: 'jtl-custom-gauge',
                data: item.rate,
                children: item.children,
                // totalCount: item.totalCount,
                // freeCount: item.freeCount,
                percentDesc: '停车率',
                chartDesc: [
                    {
                        name: '车位总数',
                        value: item.totalCount
                    },
                    {
                        name: '剩余车位',
                        value: item.freeCount
                    }
                ]
            };
        })],
        tableRowKey: 'locationId',
        tableExpandedRowKeys: getTreeIdByDepth(res.children, 'locationId', 1),
        tableRows: res.children,
        tableColumns: [
            {
                title: '区域',
                dataIndex: 'locationName'
            },
            {
                title: '车位总数',
                dataIndex: 'totalCount'
            },
            {
                title: '剩余车位',
                dataIndex: 'freeCount'
            },
            {
                title: '停车率',
                dataIndex: 'rate',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function ParkingRateExportService() {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingSpace/rts/export/usage`;
    const res = await download(url, null, 'post');
    return res;
}
