import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import EnergyIndexService from '../service/energy-index';
import { download } from '@common-src/service/request';
import { ChartRingExtend } from '@common-src/mixins/report-chart-base';
import { percentFormat } from '@common-src/filter';

export class EnergyElectricityItemsQueryModel extends QueryModel {
    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        return {
            intervalType: 'DAY',
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.getEndTime(this.dateRange[1])
        };
    }
}

export async function EnergyElectricityItemsService(model: EnergyElectricityItemsQueryModel) {
    const res = await EnergyIndexService.getElectricityItemsData(model?.toService());
    const chartOptions = {
        title: '分项用电统计',
        data: res,
        settings: {
            type: 'ring'
        },
        extend: ChartRingExtend,
        legendVisible: false,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'name'
            },
            {
                title: '能耗值',
                dataIndex: '能耗值'
            },
            {
                title: '占比',
                dataIndex: '占比',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function EnergyElectricityItemsExportService(queryModel: EnergyElectricityItemsQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/electricity/types/export/count`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
