import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import FireIndexService from '../service/fire-index';
import { download } from '@common-src/service/request';

export class FireAlarmRankQueryModel extends QueryModel {
    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        return {
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.getEndTime(this.dateRange[1])
        };
    }
}

export async function FireAlarmRankService(model: FireAlarmRankQueryModel) {
    const res = await FireIndexService.getLast7DayTop5AlarmCount(model?.toService());
    const chartOptions = {
        title: '告警排名',
        data: res,
        settings: {
            type: 'bar',
            labelMap: { value: '数量' }
        },
        extend: {
            series: {
                showBackground: true,
                label: {
                    show: true,
                    position: 'right'
                }
            }
        },
        legendVisible: false,
        tableRowKey: 'id',
        tableColumns: [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '数量',
                dataIndex: 'value'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function FireAlarmRankExportService(queryModel: FireAlarmRankQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/alarm/hts/export/rankByLocation`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
