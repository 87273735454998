import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@common-src/model/enum';
import CameraIndexService from '../service/camera-index';
import { ChartRingExtend } from '@common-src/mixins/report-chart-base';
import { percentFormat } from '@common-src/filter';
import { download } from '@common-src/service/request';

export class CameraFacilityLocationQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.CAMERA,
        mode: 'default',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        required: false
    })
    locationId: string = undefined;

    toService() {
        return {
            locationId: this.locationId
        };
    }
}

export async function CameraFacilityLocationService(model: CameraFacilityLocationQueryModel) {
    const res = await CameraIndexService.getFacilityLocationCount(model?.toService());
    const chartOptions = {
        title: '设备分布统计',
        data: res,
        settings: {
            type: 'ring'
        },
        extend: ChartRingExtend,
        tableRowKey: 'locationId',
        tableRows: res.rows,
        tableColumns: [
            {
                title: '区域',
                dataIndex: 'locationName'
            },
            {
                title: '设备数',
                dataIndex: 'totalCount'
            },
            {
                title: '占比',
                dataIndex: 'rate',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function CameraFacilityLocationExportService(queryModel: CameraFacilityLocationQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/facility/rts/export/countByLocation`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
