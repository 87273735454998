import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import BAIndexService from '../service/ba-index';
import { download } from '@common-src/service/request';
import moment from 'moment';

export class BAWaterPressQueryModel extends QueryModel {
    @QueryControl({
        label: '时间',
        type: QueryControlType.SELECT,
        options: [
            { value: 'LAST_30_DAY', name: '今天' },
            { value: 'LAST_90_DAY', name: '昨天' }
        ],
        span: 6,
        required: true
    })
    timeType: string = 'LAST_30_DAY';

    toService() {
        const params: any = {
            endTime: moment().add(1, 'day').startOf('day').toDate().getTime()
        };
        switch (this.timeType) {
            case 'LAST_30_DAY':
                params.startTime = moment().add(-30, 'day').startOf('day').toDate().getTime();
                break;
            case 'LAST_90_DAY':
                params.startTime = moment().add(-90, 'day').startOf('day').toDate().getTime();
                break;
        }
        return params;
    }
}

export async function BAWaterPressService(model: BAWaterPressQueryModel) {
    const res = await BAIndexService.getFacilityRunTime(model?.toService());
    const chartOptions = {
        title: '水压实时监测',
        data: res.data,
        settings: res.settings,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'name'
            },
            {
                title: '今天水压',
                dataIndex: 'avgTime'
            },
            {
                title: '昨天水压',
                dataIndex: 'maxTime'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function BAWaterPressExportService(queryModel: BAWaterPressQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/facility/export/runTimeStatistics`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
