import moment, { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import EnergyIndexService from '../service/energy-index';
import { download } from '@common-src/service/request';
import { percentFormat } from '@common-src/filter';

export class EnergyElectricityMonthQueryModel extends QueryModel {
    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true,
        disabled: true
    })
    dateRange: Array<Moment> = [moment().startOf('year'), moment()];

    toService() {
        return {
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.getEndTime(this.dateRange[1])
        };
    }
}

export async function EnergyElectricityMonthService(model: EnergyElectricityMonthQueryModel) {
    const res = await EnergyIndexService.getElectricityMonthData(model?.toService());
    const chartOptions = {
        title: '月度用电统计',
        data: res.data,
        settings: res.settings,
        extend: res.extend,
        tableRowKey: 'ts',
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'ts'
            },
            {
                title: '今年',
                dataIndex: '今年'
            },
            {
                title: '去年',
                dataIndex: '去年'
            },
            {
                title: '同比',
                dataIndex: '同比',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function EnergyElectricityMonthExportService(queryModel: EnergyElectricityMonthQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/electricity/month/export/count`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
