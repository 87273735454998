import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { download, post } from '@common-src/service/request';
import { assignDefaultExtend } from '@common-src/service/report';
import { changeTreeDataFieldName, getTreeIdByDepth, timeTransformData } from '../data-factory';
import { dateFormat, percentFormat } from '@common-src/filter';
import { getEndTimeByIntervalType, ReportExtraQueryModel } from './parking-report-model';
import { generateUUID } from '@common-src/utils/base_util';
import { getDateFormatByPeriodTypeEnum, periodTypeList } from '@common-src/filter/period-type';

const ELECTRICITY_ITEMS = [
    { value: 'TotalElectricityConsumption', name: '总用电' },
    { value: 'ItemizedElectricityConsumption', name: '分项用电' }
];

export class EnergyReportQueryModel extends ReportExtraQueryModel {
    @QueryControl({
        label: '属性类型',
        type: QueryControlType.SELECT,
        options: [
            { value: 'ELECTRICITY', name: '电' }
        ],
        span: 6,
        required: true
    })
    type: string = 'ELECTRICITY';

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: ELECTRICITY_ITEMS,
        mode: 'multiple',
        span: 6,
        required: true,
        defaultValue: 'ALL'
    })
    electricityItems: Array<string> = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 6,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    /**
     * 额外的配置
     */
     chartExtra: Array<any> = [
         {
             key: 'intervalType',
             type: 'select',
             options: periodTypeList,
             label: '按小时',
             onchange: (value, vm) => {
                 vm.chartSearchClick();
             }
         },
         {
             key: 'showNumber',
             type: 'checkbox',
             label: '显示数量',
             onchange: (value, vm) => {
                 vm.showNumber(value);
             }
         }
     ];

     toService() {
         const params: any = {
             startTime: this.dateRange[0].startOf('day').toDate().getTime(),
             endTime: getEndTimeByIntervalType(this.dateRange[1], this.intervalType),
             merge: this.merge,
             intervalType: this.intervalType
         };
         let paramsExtra = null;
         switch (this.type) {
             case 'ELECTRICITY':
                 params.types = this.electricityItems;
                 break;
         }
         return Object.assign(params, paramsExtra);
     }
}

export async function EnergyReportService(queryModel: EnergyReportQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/electricity/hts/count`;
    // const columns = [{ code: 'ts', name: '时间' }, { code: 'value', name: '能耗值' }, { code: 'rate', name: '占比' }];
    const columns = [{ code: 'ts', name: '时间' }, { code: 'value', name: '能耗值' }];
    const res = await post(url, queryModel?.toService());
    const data = timeTransformData(res, columns, getDateFormatByPeriodTypeEnum(queryModel.intervalType), 'name');
    const chartOptions = {
        data,
        settings: {
            type: 'line',
            yAxisType: ['normal', 'percent'],
            yAxisName: ['kWh', ''],
            axisSite: { right: _.filter(data.columns, item => item.indexOf('占比') > -1) }
        },
        extend: {
            series: {
                type: 'line',
                showSymbol: queryModel.showNumber,
                label: {
                    show: queryModel.showNumber,
                    position: 'top'
                }
            },
            yAxis: [
                {
                    splitLine: {
                        lineStyle: {
                            type: 'solid'
                        }
                    }
                },
                {
                    show: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                }
            ]
        },
        tableRowKey: (record) => {
            return record.name || generateUUID();
        },
        tableExpandedRowKeys: getTreeIdByDepth(res, 'name', 1),
        tableRows: changeTreeDataFieldName(res, 'data', 'children'),
        tableColumns: [
            {
                title: '时间',
                customRender: (text, record, index) => {
                    return record.name || dateFormat(record.ts, getDateFormatByPeriodTypeEnum(queryModel.intervalType));
                }
            },
            {
                title: '能耗值',
                dataIndex: 'value'
            },
            {
                title: '占比',
                dataIndex: 'rate',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function EnergyReportExportService(queryModel: EnergyReportQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/electricity/hts/export/count`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
