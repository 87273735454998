import moment, { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import LightIndexService from '../service/light-index';
import { download } from '@common-src/service/request';
import { percentFormat } from '@common-src/filter';

export class LightElectricityMonthQueryModel extends QueryModel {
    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true,
        disabled: true
    })
    dateRange: Array<Moment> = [moment().startOf('year'), moment()];

    toService() {
        return {
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.getEndTime(this.dateRange[1])
        };
    }
}

export async function LightElectricityMonthService(model: LightElectricityMonthQueryModel) {
    const res = await LightIndexService.getElectricityMonthData(model?.toService());
    const chartOptions = {
        title: '月度用电统计',
        data: res.data,
        settings: res.settings,
        tableRowKey: 'ts',
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'ts'
            },
            {
                title: '用电量',
                dataIndex: '用电量'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function LightElectricityMonthExportService(queryModel: LightElectricityMonthQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/electricity/month/export/count`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
