






























































































































import { Component, Ref, Watch, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { ReportChartBaseComponent } from '@common-src/mixins/report-chart-base';
import { JTLTableQueryComponent } from '@common-src/components';
import { ParkingReportQueryModel, ParkingReportService, ParkingReportExportService } from './model/parking-report-model';
import { ParkingRateService, ParkingRateExportService } from './model/parking-rate-model';
import { ParkingTrafficFlowQueryModel, ParkingTrafficFlowService, ParkingTrafficFlowExportService } from './model/parking-traffic-flow-model';
import { ParkingTrafficFlowRankingQueryModel, ParkingTrafficFlowRankingService, ParkingTrafficFlowRankingExportService } from './model/parking-traffic-flow-ranking-model';
import { FireLocationAlarmService, FireLocationAlarmQueryModel, FireLocationAlarmExportService } from './model/fire-location-alarm-model';
import { FireAlarmTrendService, FireAlarmTrendQueryModel, FireAlarmTrendExportService } from './model/fire-alarm-trend-model';
import { FireAlarmRankService, FireAlarmRankQueryModel, FireAlarmRankExportService } from './model/fire-alarm-rank-model';
import { FireReportService, FireReportQueryModel, FireReportExportService } from './model/fire-report-model';
import { CameraFacilityStatusService, CameraFacilityStatusQueryModel, CameraFacilityStatusExportService } from './model/camera-facility-status-model';
import { CameraFacilityLocationService, CameraFacilityLocationQueryModel, CameraFacilityLocationExportService } from './model/camera-facility-location-model';
import { CameraAlarmTrendService, CameraAlarmTrendQueryModel, CameraAlarmTrendExportService } from './model/camera-alarm-trend-model';
import { CameraAlarmStatusService, CameraAlarmStatusQueryModel, CameraAlarmStatusExportService } from './model/camera-alarm-status-model';
import { EnvAirQualityService, EnvAirQualityQueryModel, EnvAirQualityExportService } from './model/env-air-quality-model';
import { EnvTemperatureHumidityService, EnvTemperatureHumidityQueryModel, EnvTemperatureHumidityExportService } from './model/env-temperature-humidity-model';
import { EnergyElectricityRtsService, EnergyElectricityRtsQueryModel, EnergyElectricityRtsExportService } from './model/energy-electricity-rts-model';
import { EnergyElectricityItemsService, EnergyElectricityItemsQueryModel, EnergyElectricityItemsExportService } from './model/energy-electricity-items-model';
import { EnergyElectricityMonthService, EnergyElectricityMonthQueryModel, EnergyElectricityMonthExportService } from './model/energy-electricity-month-model';
import { EnergyReportService, EnergyReportQueryModel, EnergyReportExportService } from './model/energy-report-model';
import { BAFacilityRunTimeService, BAFacilityRunTimeQueryModel, BAFacilityRunTimeExportService } from './model/ba-facility-run-time-model';
import { BAFacilityFaultService, BAFacilityFaultQueryModel, BAFacilityFaultExportService } from './model/ba-facility-fault-model';
import { BAWaterPressService, BAWaterPressQueryModel, BAWaterPressExportService } from './model/ba-water-press-model';
import { CustomerFlowLocationService, CustomerFlowLocationQueryModel, CustomerFlowLocationExportService } from './model/customer-flow-location';
import { CustomerFlowTrendService, CustomerFlowTrendQueryModel, CustomerFlowTrendExportService } from './model/customer-flow-trend';
import { ElevatorFacilityStatusService, ElevatorFacilityStatusQueryModel, ElevatorFacilityStatusExportService } from './model/elevator-facility-status';
import { ElevatorAlarmTrendService, ElevatorAlarmTrendQueryModel, ElevatorAlarmTrendExportService } from './model/elevator-alarm-trend';
import { LightFacilityStatusService, LightFacilityStatusQueryModel, LightFacilityStatusExportService } from './model/light-facility-status';
import { LightAlarmTrendService, LightAlarmTrendQueryModel, LightAlarmTrendExportService } from './model/light-alarm-trend';
import { LightElectricityMonthService, LightElectricityMonthQueryModel, LightElectricityMonthExportService } from './model/light-electricity-month';
import { LightReportService, LightReportQueryModel, LightReportExportService } from './model/light-report-model';
import { AccessControlRtsService, AccessControlRtsQueryModel, AccessControlRtsExportService } from './model/access-control-rts';
import { AccessControlRtsTypeService, AccessControlRtsTypeQueryModel, AccessControlRtsTypeExportService } from './model/access-control-rts-type';
import { AccessControlAlarmTrendService, AccessControlAlarmTrendQueryModel, AccessControlAlarmTrendExportService } from './model/access-control-alarm-trend';
import { ComputerRoomCapacityService, ComputerRoomCapacityQueryModel, ComputerRoomCapacityExportService } from './model/computer-room-capacity';
import { percentFormat } from '@common-src/filter';
import { PropertyEntityType, SubSystemType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';

@Component
export default class ReportChartComponent extends Mixins(ReportChartBaseComponent, TableComponent) {
    timerInterval: number;
    created() {
        // 缓存告警级别数据字典
        CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE);
        // 数据自动刷新频率：60秒
        // this.timerInterval = setInterval(this.chartSearchClick, 60000);
    }
    destroyed() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
    }
    percentFormat = percentFormat;
    /**
     * 页面标题
     */
    pageTitle: string = null;

    /**
     * 获取一级面包屑标题
     */
    rootBreadcrumb: any = { title: '', link: '' };

    /**
     * 图表的数据和配置
     */
    chartOptions: any = null;

    /**
     * 表格的数据和配置
     */
    tableOptions: any = null;

    /**
     * 图标的查询方法
     */
    chartService: Function = null;

    /**
     * 表格的查询数据服务
     */
    tableService: Function = null;

    /**
     * 表格的导出数据服务
     */
    exportExcelService: Function = null;

    /**
     * 图标的搜索模型
     */
    queryModel: any = null;

    /**
     * 导出Excel的搜索模型
     */
    exportQueryModel: any = null;

    /**
     * 图表正在加载标识
     */
    chartLoading: boolean = false;

    /**
     * 图标的高度
     */
    chartHeight: number = 400;

    /**
     * 展开表格行的keys
     */
    tableExpandedRowKeys: Array<string> = [];

    /**
     * 图表查询组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME)
    queryComponent: JTLTableQueryComponent;

    mounted() {
        setTimeout(() => {
            const cHeight = document.body.clientHeight - 655;
            if (cHeight > 400) {
                this.chartHeight = cHeight;
            }
            this.initChart();
        }, 100);
    }

    @Watch('$route')
    routeChange() {
        this.resetChart();
        this.initChart();
    }

    /**
     * 获取展示类型
     * 图表/表格
     */
    get IsChart(): boolean {
        return _.get(this.chartOptions, 'settings.type') !== 'table';
    }

    /**
     * 获取图表标题
     */
    get ChartTitle() {
        return _.get(this.chartOptions, 'title');
    }

    /**
     * 获取图表的高度
     */
    get ChartHeight() {
        if (_.get(this.chartOptions, 'height') < this.chartHeight) {
            return this.chartHeight;
        }
        return _.get(this.chartOptions, 'height') || this.chartHeight;
    }

    /**
     * 获取图表表格数据
     */
    get ChartTableData() {
        return _.get(this.chartOptions, 'data.rows');
    }

    /**
     * 获取图表表格列
     */
    get ChartTableColumns() {
        return _.get(this.chartOptions, 'data.columns');
    }

    /**
     * 获取表格数据
     */
    get TableData() {
        return _.get(this.chartOptions, 'tableRows') || this.ChartTableData;
    }

    /**
     * 获取表格列
     */
    get TableColumns() {
        return _.get(this.chartOptions, 'tableColumns');
    }

    /**
     * 获取图表数据
     */
    get ChartData() {
        return _.get(this.chartOptions, 'data');
    }

    get HasMultiplyChart() {
        return Array.isArray(this.ChartData) && this.ChartData.length > 0;
    }

    /**
     * 获取图表配置
     */
    get ChartSettings() {
        return _.get(this.chartOptions, 'settings');
    }

    /**
     * 获取图表配置
     */
    get ChartExtend() {
        return _.get(this.chartOptions, 'extend');
    }

    /**
     * 获取X轴的配置
     */
    get ChartXAxis() {
        return _.get(this.chartOptions, 'xAxis');
    }

    /**
     * 获取Y轴的配置
     */
    get ChartYAxis() {
        return _.get(this.chartOptions, 'yAxis');
    }

    /**
     * 获取图表图例的显示/隐藏
     */
    get ChartLegendVisible() {
        return _.get(this.chartOptions, 'legendVisible', true);
    }

    /**
     * 获取表格子列的名称
     */
    get TableChildrenColumnName() {
        return _.get(this.chartOptions, 'tableChildrenColumnName') || 'children';
    }

    /**
     * 获取表格一行的唯一标识符
     */
    get TableRowKey() {
        return _.get(this.chartOptions, 'tableRowKey') || 'id';
    }

    /**
     * 获取分页的配置
     */
    get ReportPagination() {
        return {
            pageSize: this.pageSize,
            total: this.total,
            current: this.page,
            showSizeChanger: false,
            showQuickJumper: false
        };
    }

    /**
     * 图标搜索按钮的点击事件
     */
    chartSearchClick() {
        if (!this.queryComponent.validate()) {
            return;
        }
        return Promise.all([this.getChartData(), this.getTableData()]);
    }

    /**
     * 获取图表数据
     */
    getChartData(): Promise<any> {
        if (!this.chartService) {
            return new Promise((res, rej) => {
                res(null);
            });
        }
        this.tableExpandedRowKeys = [];
        if (!this.tableService) {
            this.listLoading = true;
        }
        this.chartLoading = true;
        return this.chartService(this.queryModel).then(res => {
            this.chartOptions = res;
            this.tableExpandedRowKeys = _.get(this.chartOptions, 'tableExpandedRowKeys') || [];
            this.exportQueryModel = _.cloneDeep(this.queryModel);
        }).catch((err) => {
            if (this.chartOptions) {
                (this.$refs.jtlChart as any).clearEcharts();
                this.chartOptions.data = null;
            }
        }).finally(() => {
            // 延迟100ms显示echarts图表
            // 为了显示echarts初始化时的动画效果
            setTimeout(() => { this.chartLoading = false; }, 100);
            if (!this.tableService) {
                this.listLoading = false;
            }
        });
    }

    /**
     * 获取表格数据
     */
    getTableData(): Promise<any> {
        if (!this.tableService) {
            return new Promise((res, rej) => {
                res(null);
            });
        }
        this.listLoading = true;
        return this.tableService(this.queryModel).then(res => {
            this.tableOptions = res;
        }).finally(() => {
            this.listLoading = false;
        });
    }

    /**
     * 导出Excel点击事件
     */
    exportExcelClick() {
        if (!this.exportExcelService) {
            this.showDeveloping();
            return;
        }
        return this.exportExcelService(this.exportQueryModel).then(res => {
            this.showMessageSuccess('导出Excel成功');
        });
    }

    /**
     * 切换显示数量
     */
    showNumber(value: any) {
        this.chartOptions.extend.series.label.show = value.target.checked;
        this.chartOptions.extend.series.showSymbol = value.target.checked;
        (this.$refs.jtlChart as any).dataHandler(false);
    }

    /**
     * 初始化图表的数据和配置
     */
    private initChart() {
        if (!this.queryComponent) {
            return;
        }
        switch (this.ChartCode) {
            case '/dashboard/parking-chart/parking':
                this.queryModel = new ParkingReportQueryModel();
                this.chartService = ParkingReportService;
                this.exportExcelService = ParkingReportExportService;
                this.pageTitle = '停车场数据分析';
                this.rootBreadcrumb = { title: '停车场管理', link: '/dashboard/parking-index' };
                break;
            case '/dashboard/parking-chart/rate':
                this.chartService = ParkingRateService;
                this.exportExcelService = ParkingRateExportService;
                this.pageTitle = '车位使用实时统计';
                this.rootBreadcrumb = { title: '停车场管理', link: '/dashboard/parking-index' };
                break;
            case '/dashboard/parking-chart/traffic-flow':
                this.queryModel = new ParkingTrafficFlowQueryModel();
                this.chartService = ParkingTrafficFlowService;
                this.exportExcelService = ParkingTrafficFlowExportService;
                this.pageTitle = '车流量统计';
                this.rootBreadcrumb = { title: '停车场管理', link: '/dashboard/parking-index' };
                break;
            case '/dashboard/parking-chart/traffic-flow-ranking':
                this.queryModel = new ParkingTrafficFlowRankingQueryModel();
                this.chartService = ParkingTrafficFlowRankingService;
                this.exportExcelService = ParkingTrafficFlowRankingExportService;
                this.pageTitle = '出入口流量排名';
                this.rootBreadcrumb = { title: '停车场管理', link: '/dashboard/parking-index' };
                break;
            case '/dashboard/parking-chart/fire-location-alarm':
                this.queryModel = new FireLocationAlarmQueryModel();
                this.chartService = FireLocationAlarmService;
                this.exportExcelService = FireLocationAlarmExportService;
                this.pageTitle = '告警分布统计';
                this.rootBreadcrumb = { title: '消防管理', link: '/dashboard/fire-index' };
                break;
            case '/dashboard/parking-chart/fire-alarm-trend':
                this.queryModel = new FireAlarmTrendQueryModel();
                this.chartService = FireAlarmTrendService;
                this.exportExcelService = FireAlarmTrendExportService;
                this.pageTitle = '告警趋势';
                this.rootBreadcrumb = { title: '消防管理', link: '/dashboard/fire-index' };
                break;
            case '/dashboard/parking-chart/fire-alarm-rank':
                this.queryModel = new FireAlarmRankQueryModel();
                this.chartService = FireAlarmRankService;
                this.exportExcelService = FireAlarmRankExportService;
                this.pageTitle = '告警排名';
                this.rootBreadcrumb = { title: '消防管理', link: '/dashboard/fire-index' };
                break;
            case '/dashboard/parking-chart/fire':
                this.queryModel = new FireReportQueryModel(SubSystemType.FIRE);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '消防数据分析';
                this.rootBreadcrumb = { title: '消防管理', link: '/dashboard/fire-index' };
                break;
            case '/dashboard/parking-chart/camera':
                this.queryModel = new FireReportQueryModel(SubSystemType.CAMERA);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '监控数据分析';
                this.rootBreadcrumb = { title: '监控管理', link: '/dashboard/camera-index' };
                break;
            case '/dashboard/parking-chart/camera-facility-status':
                this.queryModel = new CameraFacilityStatusQueryModel();
                this.chartService = CameraFacilityStatusService;
                this.exportExcelService = CameraFacilityStatusExportService;
                this.pageTitle = '设备状态统计';
                this.rootBreadcrumb = { title: '监控管理', link: '/dashboard/camera-index' };
                break;
            case '/dashboard/parking-chart/camera-facility-location':
                this.queryModel = new CameraFacilityLocationQueryModel();
                this.chartService = CameraFacilityLocationService;
                this.exportExcelService = CameraFacilityLocationExportService;
                this.pageTitle = '设备分布统计';
                this.rootBreadcrumb = { title: '监控管理', link: '/dashboard/camera-index' };
                break;
            case '/dashboard/parking-chart/camera-alarm-trend':
                this.queryModel = new CameraAlarmTrendQueryModel();
                this.chartService = CameraAlarmTrendService;
                this.exportExcelService = CameraAlarmTrendExportService;
                this.pageTitle = '近7天告警趋势';
                this.rootBreadcrumb = { title: '监控管理', link: '/dashboard/camera-index' };
                break;
            case '/dashboard/parking-chart/camera-alarm-status':
                this.queryModel = new CameraAlarmStatusQueryModel();
                this.chartService = CameraAlarmStatusService;
                this.exportExcelService = CameraAlarmStatusExportService;
                this.pageTitle = '告警状态统计';
                this.rootBreadcrumb = { title: '监控管理', link: '/dashboard/camera-index' };
                break;
            case '/dashboard/parking-chart/enviroment-air-quality':
                this.queryModel = new EnvAirQualityQueryModel();
                this.chartService = EnvAirQualityService;
                this.exportExcelService = EnvAirQualityExportService;
                this.pageTitle = '空气质量指标';
                this.rootBreadcrumb = { title: '环境管理', link: '/dashboard/enviroment-index' };
                break;
            case '/dashboard/parking-chart/enviroment-temperature-humidity':
                this.queryModel = new EnvTemperatureHumidityQueryModel();
                this.chartService = EnvTemperatureHumidityService;
                this.exportExcelService = EnvTemperatureHumidityExportService;
                this.pageTitle = '温湿度统计';
                this.rootBreadcrumb = { title: '环境管理', link: '/dashboard/enviroment-index' };
                break;
            case '/dashboard/parking-chart/enviroment':
                this.queryModel = new FireReportQueryModel(SubSystemType.ENVIROMENT);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '环境数据分析';
                this.rootBreadcrumb = { title: '环境管理', link: '/dashboard/enviroment-index' };
                break;
            case '/dashboard/parking-chart/energy-electricity-rts':
                this.queryModel = new EnergyElectricityRtsQueryModel();
                this.chartService = EnergyElectricityRtsService;
                this.exportExcelService = EnergyElectricityRtsExportService;
                this.pageTitle = '实时用电监控';
                this.rootBreadcrumb = { title: '能源管理', link: '/dashboard/energy-index' };
                break;
            case '/dashboard/parking-chart/energy-electricity-items':
                this.queryModel = new EnergyElectricityItemsQueryModel();
                this.chartService = EnergyElectricityItemsService;
                this.exportExcelService = EnergyElectricityItemsExportService;
                this.pageTitle = '分项用电统计';
                this.rootBreadcrumb = { title: '能源管理', link: '/dashboard/energy-index' };
                break;
            case '/dashboard/parking-chart/energy-electricity-month':
                this.queryModel = new EnergyElectricityMonthQueryModel();
                this.chartService = EnergyElectricityMonthService;
                this.exportExcelService = EnergyElectricityMonthExportService;
                this.pageTitle = '月度用电统计';
                this.rootBreadcrumb = { title: '能源管理', link: '/dashboard/energy-index' };
                break;
            case '/dashboard/parking-chart/energy':
                this.queryModel = new EnergyReportQueryModel();
                this.chartService = EnergyReportService;
                this.exportExcelService = EnergyReportExportService;
                this.pageTitle = '能源数据分析';
                this.rootBreadcrumb = { title: '能源管理', link: '/dashboard/energy-index' };
                break;
            case '/dashboard/parking-chart/ba-facility-run-time':
                this.queryModel = new BAFacilityRunTimeQueryModel();
                this.chartService = BAFacilityRunTimeService;
                this.exportExcelService = BAFacilityRunTimeExportService;
                this.pageTitle = '运行时长统计';
                this.rootBreadcrumb = { title: 'BA管理', link: '/dashboard/ba-index' };
                break;
            case '/dashboard/parking-chart/ba-facility-fault':
                this.queryModel = new BAFacilityFaultQueryModel();
                this.chartService = BAFacilityFaultService;
                this.exportExcelService = BAFacilityFaultExportService;
                this.pageTitle = '故障设备分类统计';
                this.rootBreadcrumb = { title: 'BA管理', link: '/dashboard/ba-index' };
                break;
            case '/dashboard/parking-chart/ba-water-press':
                this.queryModel = new BAWaterPressQueryModel();
                // this.chartService = BAWaterPressService;
                // this.exportExcelService = BAWaterPressExportService;
                this.pageTitle = '水压实时监测';
                this.rootBreadcrumb = { title: 'BA管理', link: '/dashboard/ba-index' };
                break;
            case '/dashboard/parking-chart/ba':
                this.queryModel = new FireReportQueryModel(SubSystemType.BA);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = 'BA数据分析';
                this.rootBreadcrumb = { title: 'BA管理', link: '/dashboard/ba-index' };
                break;
            case '/dashboard/parking-chart/customer-flow-retained-location':
                this.queryModel = new CustomerFlowLocationQueryModel('RETAINEDFLOW');
                this.chartService = CustomerFlowLocationService;
                this.exportExcelService = CustomerFlowLocationExportService;
                this.pageTitle = '今日客流分布';
                this.rootBreadcrumb = { title: '客流系统', link: '/dashboard/customer-flow-index' };
                break;
            case '/dashboard/parking-chart/customer-flow-in-location':
                this.queryModel = new CustomerFlowLocationQueryModel('INFLOW');
                this.chartService = CustomerFlowLocationService;
                this.exportExcelService = CustomerFlowLocationExportService;
                this.pageTitle = '今日客流分布';
                this.rootBreadcrumb = { title: '客流系统', link: '/dashboard/customer-flow-index' };
                break;
            case '/dashboard/parking-chart/customer-flow-trend':
                this.queryModel = new CustomerFlowTrendQueryModel(['INFLOW']);
                this.chartService = CustomerFlowTrendService;
                this.exportExcelService = CustomerFlowTrendExportService;
                this.pageTitle = '今日客流趋势';
                this.rootBreadcrumb = { title: '客流系统', link: '/dashboard/customer-flow-index' };
                break;
            case '/dashboard/parking-chart/customer-flow-week-trend':
                this.queryModel = new CustomerFlowTrendQueryModel(['INFLOW'], true);
                this.chartService = CustomerFlowTrendService;
                this.exportExcelService = CustomerFlowTrendExportService;
                this.pageTitle = '多日客流趋势';
                this.rootBreadcrumb = { title: '客流系统', link: '/dashboard/customer-flow-index' };
                break;
            case '/dashboard/parking-chart/customer-flow':
                this.queryModel = new FireReportQueryModel(SubSystemType.CUSTOMER_FLOW);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '客流数据分析';
                this.rootBreadcrumb = { title: '客流系统', link: '/dashboard/customer-flow-index' };
                break;
            case '/dashboard/parking-chart/elevator-facility-status':
                this.queryModel = new ElevatorFacilityStatusQueryModel();
                this.chartService = ElevatorFacilityStatusService;
                this.exportExcelService = ElevatorFacilityStatusExportService;
                this.pageTitle = '设备状态管理';
                this.rootBreadcrumb = { title: '电梯管理', link: '/dashboard/elevator-index' };
                break;
            case '/dashboard/parking-chart/elevator-alarm-trend':
                this.queryModel = new ElevatorAlarmTrendQueryModel();
                this.chartService = ElevatorAlarmTrendService;
                this.exportExcelService = ElevatorAlarmTrendExportService;
                this.pageTitle = '告警趋势';
                this.rootBreadcrumb = { title: '电梯管理', link: '/dashboard/elevator-index' };
                break;
            case '/dashboard/parking-chart/elevator':
                this.queryModel = new FireReportQueryModel(SubSystemType.ELEVATOR);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '电梯数据分析';
                this.rootBreadcrumb = { title: '电梯管理', link: '/dashboard/elevator-index' };
                break;
            case '/dashboard/parking-chart/light-facility-status':
                this.queryModel = new LightFacilityStatusQueryModel();
                this.chartService = LightFacilityStatusService;
                this.exportExcelService = LightFacilityStatusExportService;
                this.pageTitle = '设备状态统计';
                this.rootBreadcrumb = { title: '照明管理', link: '/dashboard/light-index' };
                break;
            case '/dashboard/parking-chart/light-alarm-trend':
                this.queryModel = new LightAlarmTrendQueryModel();
                this.chartService = LightAlarmTrendService;
                this.exportExcelService = LightAlarmTrendExportService;
                this.pageTitle = '告警趋势';
                this.rootBreadcrumb = { title: '照明管理', link: '/dashboard/light-index' };
                break;
            case '/dashboard/parking-chart/light-electricity-month':
                this.queryModel = new LightElectricityMonthQueryModel();
                this.chartService = LightElectricityMonthService;
                this.exportExcelService = LightElectricityMonthExportService;
                this.pageTitle = '照明管理';
                this.rootBreadcrumb = { title: '照明管理', link: '/dashboard/light-index' };
                break;
            case '/dashboard/parking-chart/light':
                this.queryModel = new LightReportQueryModel();
                this.chartService = LightReportService;
                this.exportExcelService = LightReportExportService;
                this.pageTitle = '照明管理数据分析';
                this.rootBreadcrumb = { title: '照明管理', link: '/dashboard/light-index' };
                break;
            case '/dashboard/parking-chart/access-control-rts':
                this.queryModel = new AccessControlRtsQueryModel();
                this.chartService = AccessControlRtsService;
                this.exportExcelService = AccessControlRtsExportService;
                this.pageTitle = '园内人数统计';
                this.rootBreadcrumb = { title: '门禁管理', link: '/dashboard/access-control-index' };
                break;
            case '/dashboard/parking-chart/access-control-rts-type':
                this.queryModel = new AccessControlRtsTypeQueryModel();
                this.chartService = AccessControlRtsTypeService;
                this.exportExcelService = AccessControlRtsTypeExportService;
                this.pageTitle = '人员类型统计';
                this.rootBreadcrumb = { title: '门禁管理', link: '/dashboard/access-control-index' };
                break;
            case '/dashboard/parking-chart/access-control-alarm-trend':
                this.queryModel = new AccessControlAlarmTrendQueryModel();
                this.chartService = AccessControlAlarmTrendService;
                this.exportExcelService = AccessControlAlarmTrendExportService;
                this.pageTitle = '告警趋势';
                this.rootBreadcrumb = { title: '门禁管理', link: '/dashboard/access-control-index' };
                break;
            case '/dashboard/parking-chart/access-control':
                this.queryModel = new FireReportQueryModel(SubSystemType.ACCESS_CONTROL);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '门禁数据分析';
                this.rootBreadcrumb = { title: '门禁管理', link: '/dashboard/access-control-index' };
                break;
            case '/dashboard/parking-chart/computer-room-capacity':
                this.queryModel = new ComputerRoomCapacityQueryModel();
                this.chartService = ComputerRoomCapacityService;
                this.exportExcelService = ComputerRoomCapacityExportService;
                this.pageTitle = '容量统计';
                this.rootBreadcrumb = { title: '机房动环管理', link: '/dashboard/computer-room-index' };
                break;
            case '/dashboard/parking-chart/computer-room':
                this.queryModel = new FireReportQueryModel(SubSystemType.COMPUTER_ROOM);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '机房动环数据分析';
                this.rootBreadcrumb = { title: '机房动环管理', link: '/dashboard/computer-room-index' };
                break;
            case '/dashboard/parking-chart/jyfc-overview':
                this.queryModel = new FireReportQueryModel(SubSystemType.COMPUTER_ROOM);
                this.chartService = FireReportService;
                this.exportExcelService = FireReportExportService;
                this.pageTitle = '财务概况';
                this.rootBreadcrumb = { title: '疫情防控', link: '/dashboard/fc-user-list' };
                break;
            default:
                this.pageTitle = '数据分析';
                break;
        }

        this.queryComponent.initQuery(this.queryModel);
    }

    /**
     * 重置图表数据和配置
     */
    private resetChart() {
        this.chartOptions = null;
        this.tableOptions = null;
        this.chartService = null;
        this.tableService = null;
        this.queryModel = null;
        this.exportQueryModel = null;
    }

    tableRowExpand(expanded, record) {
        if (expanded) {
            this.tableExpandedRowKeys.push(record.locationId || record.name);
        } else {
            const expandIndex = _.findIndex(this.tableExpandedRowKeys, item => item === record.locationId || record.name);
            if (expandIndex > -1) {
                this.tableExpandedRowKeys.splice(expandIndex, 1);
            }
        }
    }
}

